@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.App {
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-text-size-adjust: 100%;
  &__Contents {
    z-index: 1;
  }
}

$gray-text: #585858;
$darkgray-text: #4b4b4b;
$green-text: #0f6d82;
$chip-green: #80acb6;

.TextAccent {
  color: $green-text;
}

.BackGround {
  $circle-width: 60vw;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  &__wrap {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  &__blur {
    background-color: rgba(58, 76, 111, 0.2);
    position: absolute;

    backdrop-filter: blur(160px);
    height: 100%;
    width: 100%;
  }
  &__circle {
    width: $circle-width;
    height: $circle-width;
    position: absolute;
    border-radius: 100%;

    &__purple {
      top: 0px;
      right: -450px;
      background-color: rgba(203, 140, 185, 0.4);
    }
    &__green {
      top: 50vh;
      left: -300px;
      background-color: rgba(140, 192, 203, 0.6);
    }
  }
}

.Chips {
  flex-direction: row;
  position: relative;
  margin-top: 8px;
  &__Chip {
    display: inline-flex;
    background-color: $chip-green;
    border-radius: 9px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    padding: 3px 10px 4px;
    margin-right: 8px;

    &__Text {
      font-family: 'Noto Sans JP', sans-serif;
      font-size: 0.9vw;
      color: white;
      width: 100%;
    }
  }
}

.SectionTitle {
  margin: auto;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 30px;
  &__Text {
    font-family: 'Josefin Sans', sans-serif;
    // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    font-size: 3vw;
    letter-spacing: 0.2vw;
    color: $darkgray-text;
    opacity: 0;
    animation: 1s fadeIn forwards;
  }

  &__Bar {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: $green-text;
    // box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
  }
}

.Skills {
  margin-top: 80px;
  opacity: 0;
  animation: 5s fadeIn forwards;
  // z-index: 1;

  &__Icons {
    flex-direction: row;
    margin: 24px 0;
  }

  &__Icon {
    margin: 1vw;
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    width: 12vw;
    height: 12vw;
    &__Text {
      width: 100%;
      font-family: 'Josefin Sans', sans-serif;
      font-size: 1.5vw;
      margin-top: 8px;
      color: $darkgray-text;
    }
  }
}
