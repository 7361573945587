$gray-text: #585858;
$darkgray-text: #4b4b4b;
$green-text: #0f6d82;
$chip-green: #8cc0cb;
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Header {
  height: 56.2vw;
  position: relative;
  background-color: rgba(58, 76, 111, 0.2);

  &__Nav {
    &__Wrap {
      width: 50vw;
      height: 6vw;
      // background-color: rgba(255, 255, 255, 0.3);
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.04) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        rgba(255, 255, 255, 0.3);
      border-radius: 1vw;
      backdrop-filter: blur(7px);
      position: absolute;
      left: calc(50% - 25vw);
      top: 3vw;
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
  }

  &__wrap {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    align-items: center;
    opacity: 0;
    animation: 1s fadeIn forwards;
  }

  &__Text {
    // font-family: 'Hind', sans-serif;
    font-family: 'Josefin Sans', sans-serif;
    // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    position: absolute;

    &__role {
      color: $gray-text;
      font-size: 1.5vw;
      bottom: 6.5vw;
      letter-spacing: 0.1vw;
      left: 4.3vw;
    }
    &__name {
      color: $darkgray-text;
      font-size: 3.5vw;
      bottom: 1.5vw;
      letter-spacing: 0.14vw;
      left: 4vw;
    }
  }

  &__BackImage {
    vertical-align: bottom;
    width: 100%;
  }
  // &__CutImage {
  //   position: absolute;
  //   width: 100%;
  //   margin: auto;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   z-index: 1;
  // }
  &__BlurRect {
    position: absolute;
    bottom: -10%;
    width: 100%;
    height: 25%;
    background: rgba(241, 241, 241, 0.6);
    backdrop-filter: blur(6px);
    transform: skew(0deg, 5deg);
  }
}
