@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Projects {
  margin-top: 100px;
  margin-bottom: 100px;
  &__Blur {
    position: fixed;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(16px);
    z-index: 1;
    top: 0;
  }

  &__Wrap {
    cursor: pointer;
    border: none;
    margin: 2vw;
    padding: 0;
    position: relative;
    background-color: transparent;
  }

  &__Image {
    width: 30vw;
    border: 4px solid white;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
  }
  &__Hover {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 3px);
    // box-shadow: 2px 2px 80px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.Modal {
  position: fixed;
  // top: 0;
  // width: 210%;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  height: 90%;
  margin: auto;
  cursor: default;
  background-color: rgba(58, 76, 111, 0.6);
  backdrop-filter: blur(16px);
  border: 4px solid white;
  z-index: 2;
  opacity: 0;
  animation: 0.3s fadeIn forwards;

  &__Right {
    right: 0;
  }

  &__Top {
    width: 100%;
    height: auto;
    display: inline-flex;
    flex-direction: row;
  }

  &__Bottom {
    width: 100%;
    height: auto;
    text-align: left;
  }

  &__Image {
    cursor: pointer;
    width: 50%;
    margin-left: -4px;
    margin-top: -4px;
    border: 4px solid white;
    &__Right {
      margin-right: -4px;
    }
  }

  &__Chips {
    margin-top: 16px;
    &__Chip {
      display: inline-flex;
      background-color: transparent;
      border-radius: 8px;
      border: 1px solid white;
      padding: 3px 1vw 5px;
      margin-right: 1vw;

      &__Text {
        font-family: 'Noto Sans JP', sans-serif;

        font-size: 0.8vw;
        color: white;
        width: 100%;
      }
    }
  }

  &__Title {
    margin-left: 8vw;
    text-align: left;
    width: 50%;

    &__SubText {
      font-family: '游ゴシック', 'Yu Gothic', '游ゴシック体', YuGothic,
        sans-serif;
      font-weight: 800;
      margin-top: 120px;
      font-size: 1vw;
      color: white;
    }
    &__MainText {
      font-family: 'Josefin Sans', sans-serif;
      margin-top: 16px;
      font-size: 4vw;
      color: white;
    }
  }
  &__Desctiption {
    &__Wrap {
      margin: 32px 3vw;
    }

    &__Title {
      // font-family: 'Josefin Sans', sans-serif;
      // font-family: 'Noto Sans JP', sans-serif;
      font-family: '游ゴシック', 'Yu Gothic', '游ゴシック体', YuGothic,
        sans-serif;
      font-weight: 600;
      // font-family: 'Kaisei Opti', serif;
      font-size: 1.6vw;
      color: white;
    }

    &__SubTitle {
      font-size: 1.3vw;
      // margin-left: 0.5vw;
    }
    &__Content {
      margin: 16px 1vw;
      font-family: '游ゴシック', 'Yu Gothic', '游ゴシック体', YuGothic,
        sans-serif;
      font-weight: 600;
      font-size: 1vw;
      line-height: 2vw;
      color: white;
    }

    &__ImageWrap {
      margin: 0 1.25vw;
      width: 100%;
      display: inline-flex;
      flex-direction: row;
    }
    &__Image {
      width: 95%;
      margin-right: 8px;
    }
    &__Youtube {
      margin: 24px 1.25vw;
      width: 100%;
    }
  }
}
