$gray-text: #585858;
$darkgray-text: #4b4b4b;
$green-text: #0f6d82;
$chip-green: #659daa;

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// @import url('https://fonts.googleapis.com/css2?family=Kaisei+Opti:wght@500&display=swap');

.AboutMe {
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
  text-align: center;
  margin-top: 80px;

  &__Wrap {
    margin-bottom: 40px;
  }

  &__Title {
    width: 65vw;
    margin: auto;
    text-align: right;
    margin-bottom: 16px;

    &__Wrap {
      display: inline-flex;
      flex-direction: column;
      margin-right: 50.5vw;
      margin-top: 20px;
    }
    &__Text {
      font-family: 'Josefin Sans', sans-serif;
      // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      font-size: 2vw;
      letter-spacing: 0.2vw;
      color: $darkgray-text;
      margin-left: 0.2vw;
    }
    &__Bar {
      width: 100%;
      height: 3px;
      border-radius: 2px;
      border: none;
      background-color: $green-text;
      // box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
    }
  }
}

.Expand {
  width: 65vw;
  text-align: left;
  margin: auto;
  position: relative;
  margin-bottom: 10px;
  height: 100%;

  &__BarCircle {
    &__Wrap {
      position: absolute;
      height: calc(100% + 15px);
      width: 12px;
      left: 13.9vw;
      text-align: center;
    }
    &__Bar {
      display: inline-block;
      width: 4px;
      border-radius: 8px;
      height: 100%;
      background-color: $darkgray-text;
      margin-top: 1vw;
    }

    &__Last {
      height: calc(100% - 20px);
    }
    &__Circle {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: $darkgray-text;
      border-radius: 6px;
      border: solid rgba(255, 255, 255, 0.4) 1px;
      margin-top: 0.7vw;
    }
  }

  &__Year {
    width: 12.3vw;
    text-align: right;
    // font-family: 'Kaisei Opti', serif;
    // font-family: 'Noto Sans JP', sans-serif;
    font-family: '游ゴシック', 'Yu Gothic', '游ゴシック体', YuGothic, sans-serif;
    font-weight: 800;
    // letter-spacing: 0.1em;
    // font-family: 'Noto Sans JP', sans-serif;
    font-size: 1.4vw;
    // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    margin-right: 1.3vw;
    // margin-left: 3.1vw;
    color: $darkgray-text;
  }

  &__Title {
    display: inline-flex;
    flex-direction: row;
    vertical-align: center;
    &__Text {
      // font-family: 'Kaisei Opti', serif;
      // font-family: 'Noto Sans JP', sans-serif;
      font-family: '游ゴシック', 'Yu Gothic', '游ゴシック体', YuGothic,
        sans-serif;
      font-weight: 800;
      letter-spacing: 0.1em;
      font-size: 1.3vw;
      // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      color: $darkgray-text;
      &__button {
        padding-top: 0;
        padding-bottom: 0;
        cursor: pointer;
        background-color: transparent;
        border: none;
      }
    }

    &__Icon {
      width: 1.2vw;
      height: 1.2vw;
    }
    &__button {
      background-color: transparent;
      cursor: pointer;
      border: none;
      width: 1.2vw;
      height: 1.2vw;
      margin: {
        top: 0.5vw;
        left: 2.5vw;
        right: 0.3vw;
      }
      padding: 0;
    }
  }

  &__Description {
    width: 45vw;
    margin-left: 19vw;
    margin-top: 7px;
    font-family: '游ゴシック', 'Yu Gothic', '游ゴシック体', YuGothic, sans-serif;
    font-weight: 600;
    letter-spacing: 0.1em;
    font-size: 1vw;
    color: $darkgray-text;
    white-space: pre-wrap;
    opacity: 0;
    animation: 1s fadeIn forwards;
  }

  &__URL {
    width: 40vw;
    margin-top: 4px;
    a {
      // font-family: 'Noto Sans JP', sans-serif;
      // font-family: 'Kaisei Opti', serif;
      // font-family: 'Noto Sans JP', sans-serif;
      font-family: '游ゴシック', 'Yu Gothic', '游ゴシック体', YuGothic,
        sans-serif;
      font-weight: 600;
      letter-spacing: 0.1em;
      color: $darkgray-text;
      font-size: 1vw;
    }
    &__Icon {
      color: $darkgray-text;
      width: 1vw;
      height: 1vw;
      margin-left: 0.5vw;
      // padding-top: 8px;
    }
  }
}
